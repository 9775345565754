body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  overscroll-behavior-y: none;
  overflow-x: hidden;
}

@media only screen and (orientation:portrait) {
  .rotate {
    display: none;
  }
}

@media only screen and (orientation:landscape) {
  .rotate {
    display: block;
    position: fixed;
    color: white;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    opacity: 0.7;
    z-index: 2000;
  }

  .rotate-flex{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ------------------------------------------- COLORS

$orange: #c37e40;
$yellow: rgb(255, 230, 119);
$pink: #ffcece;
$light-grey: #bdbdbd;

// ------------------------------------------- TIMES

$fast: 0.05s;
$medium: 0.4s;
$slow: 0.8s;

// ------------------------------------------- FONTS

$main-font: "Source Code Pro", monospace;

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  font-family: $main-font;
  margin: 0;
}

p,
ul {
  font-size: unquote("clamp(0.8rem, 0.5231rem + 0.7692vw, 2rem)");
}

ul {
  padding-inline-start: 20px;
}

li {
  margin: 15px;
}

a {
  color: black;
}

h2 {
  color: white;
  font-size: unquote("clamp(1.5rem, 0.6923rem + 2.6923vw, 5rem)");
  font-weight: 900;
  opacity: 0.7;
}

h4 {
  text-decoration: none;
  // color: $orange;
  font-weight: 400;
  display: none;
}

h5 {
  position: relative;
  top: -0.8vw;
  left: 0.7vw;
  transform: rotate(90deg);
  transform-origin: left;
  align-self: flex-start;
  text-transform: uppercase;
  text-decoration: none;
  color: $orange;
  font-size: unquote("clamp(0.6rem, 0.4615rem + 0.3846vw, 1.2rem)");
  font-weight: 400;
  z-index: 10;
}

h5::after {
  content: "";
  border-bottom: 1px solid $light-grey;
  opacity: 0.5;
  display: block;
  width: 500%;
}

h6 {
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 9px;
  color: rgb(254, 255, 174);
  font-size: unquote("clamp(0.5rem, 0.3385rem + 0.5385vw, 1.2rem)");
  font-weight: 500;
  opacity: 0.7;
  transition: all $fast ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
}

h6:hover {
  color: $yellow;
}

span {
  color: $light-grey;
}

@media only screen and (max-width: 900px) {
  p {
    margin-top: 1rem;
  }
  h5 {
    left: 1.2vw;
  }
  h6 {
    color: $yellow;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

// ------------------------------------------- WRAPPERS & STUFF

div.pin-spacer {
  z-index: initial !important;
  // height: 10px !important;
}

.spacer1{
  z-index: initial;
  height: calc(2000px - 100vh)
}
.spacer2{
  z-index: initial;
  height: calc(4000px - 100vh)
}
.spacer3{
  z-index: initial;
  height: calc(4000px - 100vh)
}
.spacer4{
  z-index: initial;
  height: calc(4000px - 100vh)
}
.spacer5{
  z-index: initial;
  height: calc(4500px - 100vh);
}
.spacer6{
  z-index: initial;
  height: calc(3000px - 100vh)
}

.wrapper__image,
.wrapper__image2,
.wrapper__image3,
.wrapper__image4 {
  position: relative;
  // height: 100%;
  z-index: 5;
  // transition: transform $medium ease-in-out;
}

.wrapper__image:after:hover,
.wrapper__image2:after:hover,
.wrapper__image3:after:hover,
.wrapper__image4:after:hover {
  opacity: 1;
}

.wrapper__image:hover,
.wrapper__image2:hover,
.wrapper__image3:hover,
.wrapper__image4:hover {
  // box-shadow: 0px 5px 40px -6px rgba(0, 0, 0, 0.5);
  transition: all $fast ease-in-out !important;
  // filter: contrast(1.05) brightness(1.05);
}

.wrapper__image:hover h6,
.wrapper__image2:hover h6,
.wrapper__image3:hover h6,
.wrapper__image4:hover h6 {
  opacity: 1;
  transition: all $fast ease-in-out;
}

.wrapper__perspective {
  perspective: 1000px;
  z-index: 2;
}

video {
  border: none;
  background-color: black;
  z-index: 2;
}
iframe{
  border: none;
  background-color: white;
  z-index: 2;
}

@media only screen and (max-width: 900px) {
  .wrapper__image:hover,
  .wrapper__image2:hover,
  .wrapper__image3:hover {
    transform: scale(1);
    box-shadow: none;
  }
}

@media only screen and (max-width: 450px) {
  .wrapper__perspective {
    perspective: none;
  }
}


// ------------------------------------------- MENU

.menu {
  position: fixed;
  z-index: 100;
  height: 160px;
  top: calc(50vh - 80px);
  left: calc(100vw / 16);
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.menu a {
  z-index: 100;
}

.menu__dot-svg {
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  left: -50%;
  opacity: 0.3;
  margin-bottom: 10px;
  transition: all $fast ease-in;
  -webkit-tap-highlight-color: transparent;
}

.menu__dot-svg:hover {
  transform: scale(1.1);
  opacity: 0.6;
  transition: all $fast ease-in;
}

.menu__dot-svg:hover:after {
  display: flex;
  align-items: center;
  font-family: $main-font;
  font-size: 11px;
  position: absolute;
  left: 30px;
  content: attr(data-title);
}

.menu__dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
  margin-right: 7px;
  display: none;
}

.menu__img {
  width: 18px;
  height: 18px;
}

.menu--active {
  transform: scale(1.1) !important;
  opacity: 1 !important;
}

.menu--active img {
  width: 20px;
}

@media only screen and (max-width: 900px) {
  .menu__dot-svg {
    transform: scale(0.9);
  }

  .menu__dot-svg:hover {
    transform: scale(0.9);
    opacity: 0.3;
    animation: hoverMobile 1s ease-in;
  }
  
  .menu__dot-svg:hover:after {
    transform: scale(1.1);
    opacity: 0;
    animation: hoverMobile2 1.5s ease-in;
  }

  @keyframes hoverMobile {
    0% {
      transform: scale(0.9);
      opacity: 0.5;
    }
    100% {
      transform: scale(1.1);
      opacity: 1;
    }
  }

  @keyframes hoverMobile2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

// ------------------------------------------- HOME

.home {
  height: 100vh;
  // position: relative;
  background: url("../images/svg/zebra.svg");
}

.home__h4 {
  position: relative;
  margin: 0;
  padding-left: calc(100vw / 16);
  padding-top: calc(100vw / 20);
  z-index: 10;
}

.home__h4:after {
  content: "";
  border-bottom: 1px solid $light-grey;
  opacity: 0.5;
  display: block;
  width: 40vw;
}

.scroll {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  font-weight: 300;
  // pointer-events: none;
}

.arrow {
  transform: rotate(90deg) translateX(10px);
  transform-origin: center;
  z-index: 10;
}

.home__signature {
  position: absolute;
  width: calc(25vh + 20vw);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.home .signatur-text {
  fill: rgb(0, 0, 0);
}
.home .signatur-mask {
  fill: none;
  stroke: rgb(255, 255, 255);
  stroke-miterlimit: 10;
  stroke-width: 7.5px;
  stroke-dasharray: 4000;
  stroke-dashoffset: 4000;
}

.home .progress-bar {
  position: absolute;
  z-index: 20;
  height: 100vh;
  width: 100vw;
  background-color: rgb(219, 219, 219);
  mix-blend-mode: exclusion;
  pointer-events: none;
}

// .home__cloud1 {
//   position: absolute;
//   width: 90vw;
//   top: calc(50vh - 50%);
//   left: calc(50vw - 35%);
//   z-index: 0;
//   overflow-x: hidden;
//   filter: blur(1px);
// }

// .home__cloud2 {
//   position: absolute;
//   width: 90vw;
//   top: calc(50vh - 25%);
//   left: calc(50vw - 35%);
//   z-index: 0;
//   filter: blur(3px);
// }

// .home__cloud3 {
//   position: absolute;
//   width: 80vw;
//   top: calc(50vh - 25%);
//   left: calc(50vw - 35%);
//   z-index: 0;
//   filter: blur(1px);
// }

// .home__cloud4 {
//   position: absolute;
//   width: 80vw;
//   top: calc(50vh - 25%);
//   left: calc(50vw - 35%);
//   z-index: 0;
//   filter: blur(2px);
// }

// .home__cloud5 {
//   position: absolute;
//   width: 90vw;
//   top: calc(50vh - 25%);
//   left: calc(50vw - 35%);
//   z-index: 0;
//   transform: rotate(180deg);
//   filter: blur(3px);
// }

// .home__cloud6 {
//   position: absolute;
//   width: 90vw;
//   top: calc(50vh - 25%);
//   left: calc(50vw - 50%);
//   z-index: 0;
//   transform: rotate(180deg);
//   filter: blur(1px);
// }

@media only screen and (max-width: 900px) {
  .home img {
    transform: scale(1.2) translateY(10vh);
  }
}

// ------------------------------------------- LINUS

.linus {
  height: 100vh;
  // padding-top: 2px;
  // position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr) 5vw;
  grid-template-rows: repeat(auto-fit, calc(100vw / 8));
  gap: 1vw;
  transform: rotateX(-5deg);
  background-color: white;
}

.linus__title {
  grid-area: 1 / 2 / 2 / 4;
  align-self: flex-end;
  width: 13vw;
  z-index: 10;
}

.linus-text {
  fill: #1d1d1b;
}

.linus-mask {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.2px;
  stroke-dasharray: 65;
  stroke-dashoffset: 65;
}

#mask-li {
  stroke-dashoffset: -65;
}
// #mask-n{
//   animation: write 1s linear forwards 1s;
// }
// #mask-u{
//   animation: write 1s linear forwards 1.5s;
// }
// #mask-s{
//   animation: write 1s linear forwards 2s;
// }

.linus__line {
  grid-area: 1 / 2 / 2 / 8;
  background: $light-grey;
  opacity: 0.5;
  height: 1px;
  width: 60%;
  align-self: flex-end;
}

.linus__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
}

.linus .boxes .box1 {
  left: 300px;
  top: 100px;
  width: 30vw;
  opacity: 0.6;
}

.linus__flying-images {
  display: flex;
  position: absolute;
  z-index: 50;
  left: 30vw;
  top: calc(50vh - 40%);
}

.linus__img2,
.linus__img3,
.linus__img4 {
  width: 40vw;
  height: 40vw;
  object-fit: cover;
  object-position: top;
  transform-origin: top;
  // filter: sepia(0.7);
  opacity: 1;
  display: block;
}

.linus__flying-images .wrapper__image2,
.linus__flying-images .wrapper__image3,
.linus__flying-images .wrapper__image4 {
  position: relative;
}

.linus__flying-images .wrapper__image2:hover,
.linus__flying-images .wrapper__image3:hover,
.linus__flying-images .wrapper__image4:hover {
  z-index: 100;
  transform: scale(1.05);
}

.linus__flying-images .wrapper__image2 {
  top: 10vh;
  transform: rotate(-2deg);
}

.linus__flying-images .wrapper__image3 {
  top: 5vh;
  right: 40vw;
  transform: rotate(5deg);
}

.linus__flying-images .wrapper__image4 {
  right: 80vw;
  transform: rotate(2deg) scale(0.9);
}

.linus__p {
  grid-area: 2 / 5 / 4 / 8;
}

.linus__h5 {
  grid-area: 2 / 8 / 4 / 8;
}

.linus .wrapper__image {
  grid-area: 2 / 2 / 4 / 5;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .linus {
    padding-top: 7px;
  }

  .linus .wrapper__image {
    grid-area: 2 / 2 / 6 / 8;
    width: 100%;
  }

  .linus__p {
    grid-area: 6 / 2 / 8 / 8;
  }

  .linus__title {
    width: 17vw;
  }

  .linus__flying-images {
    left: 20vw;
    top: calc(50vh - 25%);
  }

  .linus__flying-images .wrapper__image2 {
    top: 10vh;
    transform: rotate(-2deg);
  }

  .linus__flying-images .wrapper__image3 {
    top: 5vh;
    right: 50vw;
    transform: rotate(5deg);
  }

  .linus__flying-images .wrapper__image4 {
    right: 120vw;
    transform: rotate(2deg);
    transform: scale(1);
  }

  .linus__img2,
  .linus__img3,
  .linus__img4 {
    width: 60vw;
    height: 60vw;
  }

  //   .linus .boxes .box1{
  //     left: 20vw;
  //     top: 5vw;
  //     width: 50vw;
  //     height: 30vw;
  //   }
}

// ------------------------------------------- PROGRAMMING

.programming {
  height: 100vh;
  // position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr) 5vw;
  grid-template-rows: repeat(auto-fit, calc(100vw / 8));
  gap: 1vw;
  background-color: white;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 211, 125, 0.5) 30%,
    rgba(255, 211, 125, 0.5) 70%,
    rgba(255, 255, 255, 0) 100%
  );
}

.programming__title {
  grid-area: 1 / 2 / 2 / 5;
  align-self: flex-end;
  position: relative;
  bottom: -4vw;
  transform: rotate(4deg);
  width: 18vw;
  z-index: 10;
}

.programming-mask {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 1.5px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.programming-text {
  fill: #1d1d1b;
}

.programming__line {
  grid-area: 1 / 2 / 2 / 8;
  background: $light-grey;
  opacity: 0.5;
  height: 1px;
  width: 60%;
  align-self: flex-end;
}

.programming .wrapper__image {
  grid-area: 2 / 2 / 7 / 8;
  height: 50vh;
  z-index: 50;
  // background-color: black;
}

// .wrapper__video{
//   height: 100%;
//   width: 100%;
//   display: flex;
//   justify-content: center;
// }

.slick-prev:before, .slick-next:before{
  color: black !important;
}

.wrapper__video img{
  max-height: 700px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  z-index: 0;
}

.programming__video{
  width: 80%;
  object-fit: cover;
  object-position: top;
  z-index: 0;
}

.programming__p {
  grid-area: 2 / 2 / 4 / 8;
  z-index: 2;
}

.programming__h5 {
  grid-area: 2 / 8 / 4 / 8;
}

.programming__h6 h6{
  bottom: 30px;
}

.programming__h2 {
  position: absolute;
  top: 200px;
  left: -40vw;
  right: -40vw;
  z-index: 1;
}

.scroll-inside {
  display: none;
  position: relative;
  transform: rotate(30deg) translateX(-25%) translateY(100%) scale(0.7);
  animation: blink 1s linear 1s infinite alternate;
  pointer-events: none;
}

.arrow-up {
  transform: rotate(-90deg) translateX(10%);
  transform-origin: center;
}

.scroll-inside p {
  text-align: center;
}

.wrapper__example {
  grid-area: 1 / 8 / 2 / 9;
  display: inline-block;
  position: absolute;
}

.example {
  align-self: flex-end;
  position: relative;
  left: -30%;
  bottom: -30%;
  transform: rotate(-30deg);
  display: inline-block;
  font-size: 2rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  outline: 4px dashed rgb(194, 103, 255);
  text-shadow: 3px 3px black;
  pointer-events: none;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 60;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 900px) {
  .programming .wrapper__image {
    // grid-area: 2 / 2 / 6 / 8;
    position: absolute;
    width: calc(25vh + 30vw);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }

  .programming .wrapper__image h6 {
    transform: scale(1.25);
    transform-origin: right;
  }

  .programming__video{
    width: 100%;
    display: flex;
    object-fit: contain;
    justify-content: center;
  }

  .programming__p {
    grid-area: 2 / 2 / 5 / 8;
  }

  .programming__title {
    width: 24vw;
    bottom: -5vw;
  }

  .scroll-inside {
    top: -8%;
    left: 5%;
    transform: rotate(30deg) translateX(-25%) translateY(100%) scale(0.8);
  }

  .example {
    font-size: 1rem;
    outline-width: 3px;
  }

  #code {
    display: none;
  }
}

// ------------------------------------------- GRAPHIC DESIGN

.design {
  height: 100vh;
  // position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr) 5vw;
  grid-template-rows: repeat(auto-fit, calc(100vw / 8));
  gap: 1vw;
  transform: rotateX(5deg);
  background-color: white;
}

.design__title {
  grid-area: 1 / 2 / 2 / 5;
  align-self: flex-end;
  position: relative;
  bottom: -3vw;
  transform: translateY(1.7vw) rotate(3deg);
  width: 28vw;
  z-index: 10;
}

.graphicdesign-text {
  fill: #000000;
}
.graphicdesign-mask {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 1.2px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

.design__line {
  grid-area: 1 / 2 / 2 / 8;
  background: $light-grey;
  opacity: 0.5;
  height: 1px;
  width: 60%;
  align-self: flex-end;
}

.design .wrapper-3d {
  grid-area: 4 / 4 / 6 / 6;
  display: inline-block;
  position: absolute;
  width: 30vw;
}

.design .wrapper__image,
.design .wrapper__image2,
.design .wrapper__image3 {
  overflow: visible;
  transform-style: preserve-3d;
  transform-origin: center;
  position: absolute;
  width: 35vw;
  top: 25vh;
  left: 30%;
  z-index: 1000;
}

.design .wrapper__image {
  z-index: 50;
}

.design .wrapper__image2 {
  z-index: 40;
}

.design__img {
  width: 100%;
  // height: 100%;
  overflow: visible;
  // object-fit: cover;
  object-position: center;
  align-self: end;
  display: block;
}

.design__p {
  grid-area: 2 / 2 / 4 / 8;
}

.design__h5 {
  grid-area: 2 / 8 / 5 / 8;
}

.boxes {
  position: relative;
  top: 35vh;
  left: 10vw;
  transform: scale(1.4);
  z-index: -1;
}

.box1 {
  position: absolute;
  left: 42vw;
  width: 20vw;
  height: 20vw;
  background: #ffcfc0;
  z-index: -1;
}

.box2 {
  position: absolute;
  top: 10vw;
  width: 40vw;
  height: 20vw;
  background: #ffcfc0;
  z-index: -1;
}

.box3 {
  position: absolute;
  top: 22vw;
  left: 42vw;
  width: 40vw;
  height: 20vw;
  background: #ffcfc0;
  z-index: -1;
}

@media only screen and (max-width: 900px) {
  .design .wrapper__image,
  .design .wrapper__image2,
  .design .wrapper__image3 {
    width: 60vw;
    top: 40vh;
    left: 20%;
  }

  // .box1::after, .box2::after, .box3::after{
  //   content: "";
  //   // position: relative;
  //   box-shadow: 20px 200px 0 40px rgb(176, 218, 186);
  // }

  // .boxes::after{
  //   content: "";
  //   transform: rotate(180deg);
  // }

  // .box1, .box2, .box3{
  //   box-shadow: 20px 200px 0 rgb(176, 218, 186);
  // }

  .design__p {
    grid-area: 2 / 2 / 4 / 8;
  }

  .design__title {
    bottom: -5vw;
    transform-origin: left;
    transform: rotate(3deg) scale(1.2);
    width: 34vw;
  }
}

// ------------------------------------------- MUSIC

.music {
  // height: 100vh;
  // position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr) 5vw;
  grid-template-rows: repeat(auto-fill, calc(100vw / 8));
  gap: 1vw;
  background-color: white;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 206, 206, 1) 30%,
    rgba(255, 206, 206, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );
}

.music__title {
  grid-area: 1 / 2 / 2 / 5;
  align-self: flex-end;
  position: relative;
  transform: rotate(2deg);
  transform: translateY(1.5vw);
  width: 12vw;
  z-index: 10;
}

.music-text {
  fill: #000000;
}
.music-mask {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 1.2px;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
}

.music__line {
  grid-area: 1 / 2 / 2 / 8;
  background: $light-grey;
  opacity: 0.5;
  height: 1px;
  width: 60%;
  align-self: flex-end;
  z-index: 2;
}

.music .wrapper__image {
  grid-area: 2 / 5 / 4 / 8;
  z-index: 5;
}

.music .wrapper__image2 {
  grid-area: 2 / 5 / 4 / 8;
  z-index: 6;
}

.music .wrapper__image3 {
  grid-area: 2 / 5 / 4 / 8;
  z-index: 7;
}

.music .arrow-img {
  color: white;
  position: relative;
  bottom: -10%;
  right: -25%;
  transform: rotate(90deg);
  animation: blink 1s linear 1s infinite alternate;
}

.music__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.music__iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 4;
}

.music__p,
.music__p2,
.music__p3 {
  grid-area: 2 / 2 / 4 / 5;
  z-index: 2;
}

.music__h5 {
  grid-area: 2 / 8 / 5 / 8;
}

.music__cloud1 {
  position: absolute;
  width: 90vw;
  left: -30vw;
  z-index: 0;
  overflow-x: hidden;
  // filter: blur(1px);
}

.music__cloud2 {
  position: absolute;
  width: 90vw;
  top: 0;
  right: -10vw;
  z-index: 0;
  // filter: blur(1px);
}

.music__cloud3 {
  position: absolute;
  width: 90vw;
  top: 40vh;
  right: -40vw;
  z-index: 0;
  // filter: blur(2px);
}

.music__cloud4 {
  position: absolute;
  width: 90vw;
  top: 40vh;
  left: -20vw;
  z-index: 0;
  // filter: blur(1px);
}

.music__cloud5 {
  position: absolute;
  width: 90vw;
  top: -10vh;
  right: -10vw;
  z-index: 0;
  // filter: blur(1px);
}

.music__cloud6 {
  position: absolute;
  width: 90vw;
  top: 10vh;
  left: -20vw;
  z-index: 0;
  // filter: blur(3px);
}

@media only screen and (max-width: 900px) {
  .music .wrapper__image,
  .music .wrapper__image2,
  .music .wrapper__image3 {
    grid-area: 2 / 2 / 6 / 8;
  }

  .music__p,
  .music__p2,
  .music__p3 {
    grid-area: 6 / 2 / 10 / 8;
  }

  .music__title {
    width: 16vw;
  }

  .music__cloud1,
  .music__cloud2,
  .music__cloud3,
  .music__cloud4,
  .music__cloud5,
  .music__cloud6 {
    display: none;
  }
}

// ------------------------------------------- CONTACT

.contact {
  height: 100vh;
  z-index: -10;
  // position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr) 5vw;
  grid-template-rows: repeat(auto-fill, calc(100vw / 8));
  gap: 1vw;
  transform: rotateX(-5deg);
  background-color: white;
}

.contact__title {
  grid-area: 1 / 2 / 2 / 5;
  align-self: flex-end;
  position: relative;
  transform: rotate(2deg);
  transform: translateY(1.6vw);
  width: 17vw;
  z-index: 10;
}

.contact-text {
  fill: #000000;
}

.contact-mask {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 1.2px;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
}

.contact__drawing {
  position: absolute;
  pointer-events: none;
  top: 20vw;
  left: 3vw;
  transform: rotate(180deg);
  width: 50vw;
  height: 50vh;
  z-index: 100;
}

.contact-drawing {
  fill: #ff0000;
}

.contact-mask2 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 39px;
  stroke-dasharray: 3800;
  stroke-dashoffset: -3800;
}

.contact__line {
  grid-area: 1 / 2 / 2 / 8;
  background: $light-grey;
  opacity: 0.5;
  height: 1px;
  width: 60%;
  align-self: flex-end;
}

.contact__p {
  grid-area: 2 / 2 / 4 / 8;
}

.contact__h5 {
  grid-area: 2 / 8 / 5 / 8;
}

@media only screen and (max-width: 900px) {
  .contact {
    padding-top: 10px;
  }
  .contact__title {
    width: 21vw;
  }
  .contact__drawing {
    display: none;
    top: -20%;
    transform: scale(2) rotate(180deg);
  }
}
